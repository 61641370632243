import type { FetchError } from 'ofetch'
import * as Sentry from '@sentry/nuxt'

const config = useRuntimeConfig()
const router = useRouter()

Sentry.init({
  dsn: config.public.sentry.dsn,
  environment: config.public.sentry.environment,
  dist: String(config.public.sentry.dist),
  release: config.public.sentry.release,

  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],

  // handle http errors
  beforeSend(event, eventHint) {
    const hint = eventHint as Sentry.EventHint & {
      originalException?: {
        statusCode?: FetchError['statusCode']
      }
    }
    if (hint.originalException?.statusCode !== undefined) {
      const status: number = hint.originalException.statusCode
      switch (status) {
        case 400:
        case 401:
        case 403:
        case 404:
        case 422:
        case 429:
          return null
        default:
          break
      }
    }
    return event
  },
})
